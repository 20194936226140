// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_2521 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 800px;

  .backstretch {
    position: relative;
  
    // for top of page gradient
    &:after {
      @include before(100%, 450px);
      background: url(/i/design/gradient-btm.png) repeat-x bottom left;
      bottom: 0px;
      left: 0;
    }
  
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 450px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 60px;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;

  @media only screen and (max-width: 990px) {
    bottom: 100px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    bottom: 40px;
  }

  span {
    display: block;
    font-size: 18px;
    margin: 20px 0 0 0;
  }
}